import InActiveMarket from './views/inActiveMarket.vue'

// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const merchant = () => import('./views/index')
const pendingMerchant = () => import('./views/pendingMerchant')
const addMerchant = () => import('./views/addMerchant')
const allTax = () => import('./tax/views/index')
const financialOperations = () => import('./views/financialOperations')
const addTax = () => import('./tax/views/addTax')
const customers = () => import('./views/customers')
const reviews = () => import('./views/reviews')
const orders = () => import('./views/orders')
const restoreDeleting = () => import('./views/restoreDeleting')
// start Routes
export default [
  {
    path: '/marketplace',
    name: 'merchant',
    component: dashboardLayout,
    children: [
      {
        path: 'merchant',
        name: 'merchant',
        meta: {
          name: 'merchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: merchant
      },
      {
        path: 'show-merchant/:id',
        name: 'showMerchant',
        meta: {
          name: 'showMerchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: InActiveMarket
      },
      {
        path: 'add-merchant',
        name: 'addMerchant',
        meta: {
          name: 'addMerchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: addMerchant
      },
      {
        path: 'add-merchant',
        name: 'addMerchant',
        meta: {
          name: 'addMerchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: addMerchant
      },
      {
        path: 'restore-deleting',
        name: 'restoreDeleting',
        meta: {
          name: 'restoreDeleting',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: restoreDeleting
      },
      {
        path: 'pending-merchant',
        name: 'pendingMerchant',
        meta: {
          name: 'pendingMerchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: pendingMerchant
      },
      {
        path: 'show-inactive-marketplace/:id',
        name: 'inActiveMarket',
        meta: {
          name: 'inActiveMarket',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: InActiveMarket
      },
      {
        path: 'edit-marketplace/:id',
        name: 'editMarket',
        meta: {
          name: 'editMarket',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: InActiveMarket
      },
      {
        path: 'tax',
        name: 'tax',
        meta: {
          name: 'tax',
          parentName: 'tax',
          userType: '',
          permission: ''
        },
        component: allTax
      },
      {
        path: 'add-tax',
        name: 'addTax',
        meta: {
          name: 'addTax',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: addTax
      },
      {
        path: 'customers',
        name: 'customers',
        meta: {
          name: 'customers',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: customers
      },
      {
        path: 'reviews',
        name: 'reviews',
        meta: {
          name: 'reviews',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: reviews
      },
      {
        path: 'orders',
        name: 'orders',
        meta: {
          name: 'orders',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: orders
      },
      {
        path: 'financialOperations',
        name: 'financialOperations',
        meta: {
          name: 'financialOperations',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: financialOperations
      }
    ]
  }

]
